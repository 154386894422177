import { UserOptionsEnum } from '@appTypes/common.types';
import { environment } from '@env/environment';

export const PAGES_REGISTRY = [
  { label: 'Tours', breadcrumbLabel: 'Search tours', path: './tours' },
  { label: 'Hotels', breadcrumbLabel: 'Search hotels', path: './hotels' },
  { label: 'Flights', breadcrumbLabel: 'Search flights', path: './avia' },
  { label: 'Accounts', path: './accounts' },
  { label: 'Checkout', path: './checkout' },
  /* ... */
  { label: 'Kazunion tour', path: environment.desktopDefaultPagePath },
  { label: 'Main', path: environment.desktopDefaultPagePath }
];
export const HEADER_MENU = [
  { label: 'Tours', icon: 'tours-icon', path: './tours' },
  { label: 'Hotels', icon: 'hotels-icon', path: './hotels' },
  { label: 'Flights', icon: 'avia-icon', path: './avia' }
  //{ label: 'Personal cabinet', path: './cabinet' }
];

export const USER_OPTIONS_MENU = [
  {
    value: UserOptionsEnum.changePassword,
    label: 'Change Password'
  },
  // @Note - QA-1996
  // {
  //   value: UserOptionsEnum.accounts,
  //   label: 'Accounts'
  // },
  {
    value: UserOptionsEnum.logOut,
    label: 'Log Out'
  }
];
